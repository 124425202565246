const jobsData = [
    {
        title: "Data Scientist",
        companyName: "Glovo",
        companyLogoPath: "/jobs/glovo.webp",
        startDate: "Jan 2021",
        endDate: "Sep 2023",
        description: "Developed and implemented data science models, contributing to the allocation of a total budget exceeding €100 million. Designed and evaluated multiple statistical experiments, resulting in business decisions that increased yearly profits by €450-900K. Demonstrated proactive task and project management skills, actively participating in Hackathons, mentorship programs, and team initiatives.",
    },
    {
        title: "Podcast Host & Web Developer",
        companyName: "Data For Future",
        companyLogoPath: "/jobs/data-for-future.webp",
        startDate: "Mar 2020",
        endDate: "Present",
        description: "At Data For Future, we discuss hot topics about Data, Artificial Intelligence, and how it helps us move towards a sustainable future for society and planet.",
    },
    {
        title: "Machine Learning Engineer",
        companyName: "Omdena",
        companyLogoPath: "/jobs/omdena.webp",
        startDate: "Oct 2020",
        endDate: "Jan 2021",
        description: "Working in a team of 50+ Data Scientists around the world as well as Kenya Red Cross, I developed AI-powered solutions to assess the impact of locusts in Kenya and leverage more precise and robust humanitarian aid.",
    },
]

export default jobsData;
