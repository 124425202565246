import React from 'react';

export default function Job({
    title,
    companyName,
    companyLogoPath,
    startDate,
    endDate,
    description,
}) {
    return (
        <div className="job">
            <img src={companyLogoPath} alt={title} width={70} />
            <div className="details">
                <h3>{title}</h3>
                <h4>{companyName}</h4>
                <p className="dates">{startDate} - {endDate}</p>
                <p className="description">{description}</p>
            </div>
        </div>
    );
};
