import React from "react";
import Icon from "../components/Icon";
import { FaGithub, FaLinkedin, FaEnvelope, FaPhone } from "react-icons/fa6";

function Footer() {
    return (
        <footer className="bg-dark">
            <nav>
                <ul>
                    <li>
                        <a href='https://www.linkedin.com/in/pavlo-seimskyi' target="_blank" rel="noreferrer">
                            <Icon Data={FaLinkedin}/>LinkedIn
                        </a>
                    </li>
                    <li>
                        <a href='https://github.com/pavlo-seimskyi' target="_blank" rel="noreferrer">
                            <Icon Data={FaGithub}/>GitHub
                        </a>
                    </li>
                    <li>
                        <a href='mailto:pavseim@gmail.com' target="_blank" rel="noreferrer">
                            <Icon Data={FaEnvelope}/>Email
                        </a>
                    </li>
                    <li>
                        <Icon Data={FaPhone}/>
                        <span>+34 630 603 763</span>
                    </li>
                </ul>
            </nav>
            <p id="copyright">Copyright &copy; 2024 Pavlo Seimskyi</p>
        </footer>
    );
};

export default Footer;
