import React from "react";
import { IconContext } from "react-icons";

export default function Icon({ Data, size }) {
    return (
        <IconContext.Provider value={{ className: "icon", size }}>
          <Data/>
        </IconContext.Provider>
    );
};
