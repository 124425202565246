import { LuBrainCircuit } from "react-icons/lu";
import { FaReact } from "react-icons/fa";
import { SiNodedotjs, SiPython } from "react-icons/si";
import { BiLogoPostgresql } from "react-icons/bi";

const ICON_SIZE = 30;

const skills = [
    { icon: FaReact, size: ICON_SIZE, name: "React" },
    { icon: SiNodedotjs, size: ICON_SIZE, name: "Node" },
    { icon: SiPython, size: ICON_SIZE, name: "Python" },
    { icon: BiLogoPostgresql, size: ICON_SIZE, name: "SQL" },
    { icon: LuBrainCircuit, size: ICON_SIZE, name: "ML" },
];

export default skills;
