import { configureStore } from "@reduxjs/toolkit";
import projectWindowReducer from "../reducers/projectWindow";

const store = configureStore({
    reducer: {
        projectWindow: projectWindowReducer,
    },
});

export default store;
