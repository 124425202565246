import React from "react";
import Icon from './Icon';
import { IoMdEye } from 'react-icons/io';
import { useDispatch } from 'react-redux';

export default function ProjectWindow({
    title,
    description,
    imagePath,
    projectLink,
    techStack,
    lessonsLearned,
}) {
    const dispatch = useDispatch();

    function handleClose() {
        dispatch({ type: "projectWindow/close" });
    };

    return (
        <>
            <div className="overlay" onClick={handleClose}/>
            <div id="project-window" className="bg-light">
                <button id="close-button" onClick={handleClose}>
                    &#215;
                </button>
                <h3>{title}</h3>
                <img src={imagePath} alt={title} />
                <p>{description}</p>
                <h4>Tech stack</h4>
                <ul>
                    {techStack.map((technology, idx) => (
                        <li key={idx}>
                            <Icon Data={technology.icon}/>{technology.text}
                        </li>
                    ))}
                </ul>
                <h4>Lessons Learned</h4>
                <ul>
                    {lessonsLearned.map((lesson, idx) => (
                        <li key={idx}>
                            {lesson}
                        </li>
                    ))}
                </ul>
                <a href={projectLink} target="_blank" rel="noopener noreferrer">
                    <Icon Data={IoMdEye} />View the project
                </a>
            </div>
        </>
    );
};
