import '../styles/main.css';
import Intro from '../sections/Intro';
import WorkExperience from '../sections/WorkExperience';
import Portfolio from '../sections/Portfolio';
import Footer from '../sections/Footer';
import Contact from '../sections/Contact';

function App() {
  return (
    <>
      <main>
        <Intro />
        <WorkExperience />
        <Portfolio />
        <Contact />
      </main>
      <Footer />
    </>
  );
};

export default App;
