import React from "react";
import jobsData from "../data/jobs";
import Job from "./Job";

export default function Jobs() {
    return (
        <div id="jobs">
            {jobsData.map((job, idx) => <Job key={idx} {...job} />)}
        </div>
    );
};
