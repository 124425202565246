import React from 'react';
import { useDispatch } from 'react-redux';
import { IoMdEye } from "react-icons/io";
import Icon from './Icon';

export default function Project({
    id,
    title,
    description,
    imagePath,
    projectLink,
}) {
    const dispatch = useDispatch();

    function trim(text, maxLen = 104) {
        return text.length < maxLen ? text : text.slice(0, maxLen) + "...";
    };

    function openProjectWindow() {
        dispatch({ type: "projectWindow/open", payload: id });
    };

    return (
        <div className="bg-light project" onClick={openProjectWindow}>
            <img src={imagePath} alt={title} />
            <h3>{title}</h3>
            <p>{trim(description)}</p>
            <a href={projectLink} target="_blank" rel="noopener noreferrer">
                <Icon Data={IoMdEye}/>View the project
            </a>
        </div>
    );
};
