import React from "react";
import Project from "../components/Project";
import { projectData } from "../data/projects";

export default function Projects() {
    return (
        <div id="projects">
            {projectData.map((project, idx) => (
                <Project key={idx} id={idx} {...project}/>
            ))}
        </div>
    );
};
