import React from "react";
import Portrait from "../img/portrait.webp";
import Icon from '../components/Icon';
import skills from "../data/skills";

export default function Intro() {
    return (
        <section className="bg-dark">
            <div className="container" id="intro">
                <h1>Hi! I'm Pavlo</h1>
                <img id="portrait" src={Portrait} alt="portrait" />
                <p>A passionate Software Engineer with a background in Data Science. What started as a curiosity for Machine Learning quickly transformed into a deep-seated love for building applications. Today, I'm fully committed to engineering, leveraging my expertise to craft robust, efficient, and scalable software solutions.</p>
                <p>My expertise is in:</p>
                <div id="top-skills">
                    {skills.map((skill, idx) => (
                        <p className="bg-light" key={idx}>
                            <Icon Data={skill.icon} size={skill.size}/>{skill.name}
                        </p>
                    ))}
                </div>
            </div>
            
        </section>
    );
};
