import { createSlice } from "@reduxjs/toolkit";

export const projectWindowSlice = createSlice({
    name: "projectWindow",
    initialState: {
        isOpen: false,
        selectedProjectId: null,
    },
    reducers: {
        open: (state, action) => {
            state.isOpen = true;
            state.selectedProjectId = action.payload;
        },
        close: (state) => {
            state.isOpen = false;
            state.selectedProjectId = null;
        },
    },
})

export default projectWindowSlice.reducer;
