import React from "react";
import Jobs from "../components/Jobs";

export default function WorkExperience() {
    return (
        <section className="bg-light">
            <div className="container" id="work-experience">
                <h2>Work Experience</h2>
                <Jobs/>
            </div>
        </section>
    );
};
