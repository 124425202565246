import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import Projects from "../components/Projects";
import ProjectWindow from "../components/ProjectWindow";
import { projectData } from "../data/projects";

export default function Portfolio() {
    const {
        isOpen: isWindowOpen,
        selectedProjectId,
    } = useSelector(state => state.projectWindow);

    useEffect(() => {
        document.body.style.overflow = isWindowOpen ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isWindowOpen]);

    return (
        <section className="bg-dark">
            <div className="container" id="portfolio">
                <h2>Projects</h2>
                <Projects/>
                {isWindowOpen && <ProjectWindow {...projectData[selectedProjectId]}/>}
            </div>
        </section>
    );
};
